import { ImageMode } from "../types/account";

/** @deprecated Use useUserData.ts instead */
export default class Store {
  static myInstance: null | Store = null;

  _username = "";
  _accountId = "";
  _imgMode: ImageMode = "RUFFLE";
  _selectedDinoz = "";
  _isAuthenticated = false;
  _totalPrice = 0;
  _selectedUserId = "";
  _token = "";

  /**
   * @returns {Store}
   * This acts as a Singleton to store some values.
   */
  static getInstance() {
    if (Store.myInstance == null) {
      Store.myInstance = new Store();
    }

    return this.myInstance;
  }

  getUsername() {
    return this._username;
  }

  setUsername(name) {
    this._username = name;
  }

  getAccountId() {
    return this._accountId;
  }

  setAccountId(id) {
    this._accountId = id;
  }

  getImgMode() {
    return this._imgMode;
  }

  setImgMode(imgMode: ImageMode) {
    this._imgMode = imgMode;
  }

  getSelectedDinoz() {
    return this._selectedDinoz;
  }

  setSelectedDinoz(id) {
    this._selectedDinoz = id;
  }

  isUserAuthenticated() {
    return this._isAuthenticated;
  }

  setUserAuthenticated(flag) {
    this._isAuthenticated = flag;
  }

  getTotalPrice() {
    return this._totalPrice;
  }

  setTotalPrice(price) {
    this._totalPrice = price;
  }

  getToken() {
    return this._token;
  }

  setToken(token) {
    this._token = token;
  }
}
